/*!
 * FileInput <_LANG_> Translations
 *
 * This file must be loaded after 'fileinput.js'. Patterns in braces '{}', or
 * any HTML markup tags in the messages must not be converted or translated.
 *
 * @see http://github.com/kartik-v/bootstrap-fileinput
 *
 * NOTE: this file must be saved in UTF-8 encoding.
 */
(function ($) {
    "use strict";

    $.fn.fileinputLocales['sl'] = {
        fileSingle: 'datoteka',
        filePlural: 'datoteke',
        browseLabel: 'Izberi &hellip;',
        removeLabel: 'Odstrani',
        removeTitle: 'Odstrani izbrane datoteke',
        cancelLabel: 'Prekliči',
        cancelTitle: 'Prekini',
        uploadLabel: 'Shrani',
        uploadTitle: 'Shrani izbrane datoteke',
        msgNo: 'Ne',
        msgCancelled: 'Preklicano',
        msgZoomTitle: 'Ogled podrobnosti',
        msgZoomModalHeading: 'Podroben predogled',
        msgSizeTooLarge: 'Datoteka "{name}" (<b>{size} KB</b>) presega največjo dovoljeno velikost <b>{maxSize} KB</b>.',
        msgFilesTooLess: 'Izbrati morate vsaj <b>{n}</b> {files} za nalaganje.',
        msgFilesTooMany: 'Število izbranih datotek <b>({n})</b> presega dovoljeno št. <b>{m}</b>.',
        msgFileNotFound: 'Datoteke "{name}" ni bilo mogoče najti!',
        msgFileSecured: 'Varnostne omejitve preprečujejo branje datoteke "{name}".',
        msgFileNotReadable: 'Datoteka "{name}" ni berljiva.',
        msgFilePreviewAborted: 'Predogled preklican za datoteko "{name}".',
        msgFilePreviewError: 'Napaka pri branju datoteke "{name}".',
        msgInvalidFileType: 'Napačen tip datoteke "{name}". Samo "{types}" tipi datotek so dovoljeni.',
        msgInvalidFileExtension: 'Napačna končnica datoteke "{name}". Samo "{extensions}" končnice so dovoljene.',
        msgUploadAborted: 'Nalaganje datotek je bilo preklicano',
        msgValidationError: 'Validacijska napaka',
        msgLoading: 'Nalaganje {index} od {files} &hellip;',
        msgProgress: 'Nalaganje {index} od {files} - {name} - {percent}% končano.',
        msgSelected: '{n} {files} izbranih',
        msgFoldersNotAllowed: 'Odlagajte samo datoteke! Preskočilo {n} direktorijev.',
        msgImageWidthSmall: 'Širina slike "{name}" mora biti vsaj {size} px.',
        msgImageHeightSmall: 'Višina slike "{name}" mora biti vsaj {size} px.',
        msgImageWidthLarge: 'Širina slike "{name}" ne sme presegati {size} px.',
        msgImageHeightLarge: 'Višina slike "{name}" ne sme presegati {size} px.',
        msgImageResizeError: 'Nesupešno spreminjanje dimenzij slike.',
        msgImageResizeException: 'Napaka pri spreminjanju dimenzij slike.<pre>{errors}</pre>',
        dropZoneTitle: 'Odlagajte datoteke tu &hellip;',
        fileActionSettings: {
            removeTitle: 'Odstani datoteko',
            uploadTitle: 'Shrani datoteko',
            indicatorNewTitle: 'Še ni shranjeno',
            indicatorSuccessTitle: 'Shranjeno',
            indicatorErrorTitle: 'Napaka pri shranjevanju',
            indicatorLoadingTitle: 'Shranjujem ...'
        }
    };
})(window.jQuery);
